import { ProjectTeamMember } from "components/ProjectTeamMembers/types"
import { Nullable } from "tsdef"
import { PersonPhoto } from "views/persons/components/person-avatar/constants/person-avatar.definitions"
import {
  PhoneDataType,
  SalaryPackageType,
  TaggedEmail,
  WebSite
} from "views/persons/person.types"
import { ContactLookupFormValues } from "../components/ContactLookup/definitions"

export type Address = {
  addressLine1: Nullable<string>
  addressLine2: Nullable<string>
  addressLine3: Nullable<string>
  addressLine4: Nullable<string>
  city: Nullable<string>
  country: Nullable<string>
  postcode: Nullable<string>
  location: Nullable<string>
}

export type LatestExperienceType = {
  companyName: string
  compensation: Nullable<SalaryPackageType>
  description: string
  endDate: string
  id: string
  startDate: string
  title: string
  companyId: string
}

export type AllExperienceDatesType = {
  endDate: Nullable<string>
  startDate: Nullable<string>
}

export type InternalPersonType = {
  id: string
  firstName: Nullable<string>
  middleName: Nullable<string>
  lastName: Nullable<string>
  knownAs: Nullable<string>
  title: Nullable<string>
  name: string
  bio: string
  photo: Nullable<PersonPhoto>
  isOfflimits: boolean
  allExperienceDates: AllExperienceDatesType[]
  latestExperience: LatestExperienceType
  dataPoolId: Nullable<string>
  phones: PhoneDataType[]
  taggedEmails: TaggedEmail[]
  webSites: WebSite[]
  teamMembers: ProjectTeamMember[]
} & Address

export type InternalSearchPageParams = {
  hasNextPage: boolean
  hasPreviousPage: boolean
  maxPages: number
  pageCount: number
  pageNumber: number
  pageSize: number
  totalItemCount: number
}

export enum Conditions {
  AND = "and",
  OR = "or"
}

export enum Booleans {
  Contains = "contains",
  Is = "is",
  IsLessThan = "IsLessThan",
  Not = "not"
}

export enum TimeFrameType {
  Current = "current",
  Past = "past",
  Either = "either"
}

export type FieldBundle = {
  id?: string
  condition?: Booleans
  values?: string[]
  timeFrame?: string
}

export type SectionBundle = {
  id: string
  condition?: Conditions
  fields?: FieldBundle
}

export enum InternalFilterType {
  Sections = "sections",
  SectionsBundleSearchUsingANDLogic = "condition"
}

export type InternalSearchFilters = {
  [InternalFilterType.Sections]?: SectionBundle[]
  [InternalFilterType.SectionsBundleSearchUsingANDLogic]?: Conditions
}

export type Section = {
  id: string
  name: string
}

export type Field = {
  id: number
  name: string
  fieldType: string
  showTimeFrame: boolean
}

export type SelectSection = {
  value: string
  label: string
}

export type InternalSearchState = {
  filters: InternalSearchFilters
  sections: SelectSection[]
  isLoadingSearchResults: boolean
  internalPersons: InternalPersonType[]
  internalSearchPage: InternalSearchPageParams
  isResetFilters: boolean
  isLookup: boolean
  lookupSearchString: ContactLookupFormValues
  selectedInternalSearchPersonId: string
}

export enum InternalSearchActionTypes {
  UpdateFilters = "updateFilters",
  SetIsLoadingSearchResults = "setIsLoadingSearchResults",
  SetSections = "setSections",
  ClearCertainFilters = "clearCertainFilters",
  SetInternalPersons = "setInternalPersons",
  SetInternalSearchPage = "setInternalSearchPage",
  ResetFilters = "resetFilters",
  SetIsResetFilters = "setIsResetFilters",
  SetIsLookup = "setIsLookup",
  SetLookupSearchString = "setLookupSearchString",
  SetSelectedInternalSearchPersonId = "setSelectedInternalSearchPersonId",
  UpdatePerson = "updatePerson"
}

export type InternalSearchContextValues = InternalSearchState & {
  updateFilters: UpdateFilters
  setIsLoadingSearchResults: SetIsLoadingSearchResults
  setSections: SetSections
  addArrayBasedInternalFilter: AddArrayBasedInternalFilter
  removeArrayBasedInternalFilter: RemoveArrayBasedInternalFilter
  clearArrayBasedFilter: ClearArrayBasedFilter
  clearFilters: ClearFilters
  setInternalPersons: SetInternalPersons
  setInternalSearchPage: SetInternalSearchPage
  resetFilters: ResetFilters
  setIsResetFilters: SetIsResetFilters
  setIsLookup: SetIsLookup
  setLookupSearchString: SetLookupSearchString
  setSelectedInternalSearchPersonId: SetSelectedInternalSearchPersonId
  updatePerson: UpdatePerson
}

export type UpdateFilters = (data: InternalSearchFilters) => void
export type SetIsLoadingSearchResults = (
  isLoadingSearchResults: boolean
) => void
export type SetSections = (sections: SelectSection[]) => void
export type AddArrayBasedInternalFilter = (
  filterName: InternalFilterType | string,
  value: string | string[]
) => void
export type RemoveArrayBasedInternalFilter = (
  filterName: InternalFilterType | string,
  value: string
) => void
export type ClearArrayBasedFilter = (
  filterName: InternalFilterType | string
) => void
export type ClearFilters = (data: InternalSearchFilters) => void
export type SetInternalPersons = (data: InternalPersonType[]) => void
export type SetInternalSearchPage = (page: InternalSearchPageParams) => void
export type ResetFilters = () => void
export type SetIsResetFilters = (data: boolean) => void
export type SetIsLookup = (data: boolean) => void
export type SetLookupSearchString = (data: ContactLookupFormValues) => void
export type SetSelectedInternalSearchPersonId = (personId: string) => void
export type UpdatePerson = (person: InternalPersonType) => void
