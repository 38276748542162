import React from "react"
import get from "lodash/get"
import { Div, Flex } from "@ikiru/talentis-fpc"
import { useRadioField } from "../hooks"
import { FormikToggle } from "components/functional/formik/formik-toggle/FormikToggle"
import { messages } from "setup/messages/messages"
import {
  InternalFilterType,
  TimeFrameType
} from "../InternalSearchModule/types"
import { InternalSearchCriteriaField } from "../definitions"
import { useInternalSearch } from "../InternalSearchModule/context"

type TimeFrameProps = {
  fieldName: InternalSearchCriteriaField | string
}

export const TimeFrame = ({ fieldName }: TimeFrameProps) => {
  const onChange = useRadioField(fieldName)
  const { filters } = useInternalSearch()

  const selected: any = get(filters, fieldName, TimeFrameType.Either)

  return (
    <Flex flexDirection="row" width="100%" mt="xxs">
      <Div mr="s">
        <FormikToggle
          small
          type="radio"
          hideUnderline
          notCheckedFade
          name={fieldName}
          onChange={onChange}
          labelProps={{ ml: "xxs" }}
          value={TimeFrameType.Current}
          checked={selected === TimeFrameType.Current}
          label={messages.talentGraphSearch.searchLogic.current}
        />
      </Div>
      <Div mr="s">
        <FormikToggle
          small
          type="radio"
          hideUnderline
          notCheckedFade
          name={fieldName}
          onChange={onChange}
          labelProps={{ ml: "xxs" }}
          value={TimeFrameType.Past}
          checked={selected === TimeFrameType.Past}
          label={messages.talentGraphSearch.searchLogic.past}
        />
      </Div>
      <Div>
        <FormikToggle
          small
          type="radio"
          hideUnderline
          notCheckedFade
          name={fieldName}
          onChange={onChange}
          labelProps={{ ml: "xxs" }}
          value={TimeFrameType.Either}
          checked={selected === TimeFrameType.Either}
          label={messages.talentGraphSearch.searchLogic.either}
        />
      </Div>
    </Flex>
  )
}
