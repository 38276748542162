import {
  cloneDeep,
  get,
  isBoolean,
  isEmpty,
  noop,
  pickBy,
  set,
  toPath
} from "lodash"
import {
  InternalSearchContextValues,
  InternalSearchState,
  InternalSearchFilters,
  InternalSearchActionTypes
} from "./types"
import { defaultFilters } from "./consts"

function updatePersonObject(original, updates) {
  const updatedObject = { ...original }

  for (const key in updates) {
    if (updates.hasOwnProperty(key)) {
      if (updatedObject.hasOwnProperty(key)) {
        if (
          typeof updates[key] === "object" &&
          updates[key] !== null &&
          !Array.isArray(updates[key])
        ) {
          updatedObject[key] = updatePersonObject(
            updatedObject[key],
            updates[key]
          )
        } else {
          updatedObject[key] = updates[key]
        }
      }
    }
  }

  return updatedObject
}

export const initialInternalSearchState: InternalSearchState = {
  //@ts-ignore
  filters: defaultFilters,
  sections: [],
  isLoadingSearchResults: false,
  internalPersons: [],
  internalSearchPage: {
    hasNextPage: false,
    hasPreviousPage: false,
    maxPages: 25,
    pageCount: 0,
    pageNumber: 0,
    pageSize: 0,
    totalItemCount: 0
  },
  isResetFilters: false,
  isLookup: false,
  lookupSearchString: { quickSearchValue: "" },
  selectedInternalSearchPersonId: ""
}

export const internalSearchReducer = (
  state: InternalSearchState,
  action: { type: string; payload?: any }
) => {
  const { type, payload } = action

  switch (type) {
    case InternalSearchActionTypes.UpdateFilters:
      const newFilters: InternalSearchFilters = { ...state.filters }

      for (const [filterName, filterValue] of Object.entries(payload)) {
        set(newFilters, filterName, filterValue)
      }

      return {
        ...state,
        filters: pickBy(newFilters, (value) =>
          isBoolean(value) ? !!value : !isEmpty(value)
        )
      }
    case InternalSearchActionTypes.ClearCertainFilters:
      const futureFilters: InternalSearchFilters = cloneDeep(state.filters)

      for (const [filterName] of Object.entries(payload)) {
        const pathArr = toPath(filterName)
        const deleteKey = pathArr.pop()
        if (pathArr.length) {
          get(futureFilters, pathArr)?.splice(deleteKey, 1)
        } else if (deleteKey) {
          delete futureFilters[deleteKey as keyof InternalSearchFilters]
        }
      }

      return {
        ...state,
        filters: pickBy(futureFilters, (value) =>
          isBoolean(value) ? !!value : !isEmpty(value)
        )
      }
    case InternalSearchActionTypes.SetIsLoadingSearchResults:
      return {
        ...state,
        isLoadingSearchResults: payload
      }

    case InternalSearchActionTypes.SetSections:
      return {
        ...state,
        sections: payload
      }
    case InternalSearchActionTypes.SetInternalPersons:
      return {
        ...state,
        internalPersons: payload,
        isLoadingSearchResults: false
      }
    case InternalSearchActionTypes.SetInternalSearchPage: {
      return { ...state, internalSearchPage: { ...payload } }
    }
    case InternalSearchActionTypes.ResetFilters: {
      return {
        ...initialInternalSearchState,
        filters: {},
        sections: state.sections,
        isResetFilters: false
      }
    }
    case InternalSearchActionTypes.SetIsResetFilters: {
      return {
        ...state,
        isResetFilters: payload
      }
    }
    case InternalSearchActionTypes.SetIsLookup:
      return {
        ...state,
        isLookup: payload
      }
    case InternalSearchActionTypes.SetLookupSearchString:
      return {
        ...state,
        lookupSearchString: payload
      }
    case InternalSearchActionTypes.SetSelectedInternalSearchPersonId:
      return {
        ...state,
        selectedInternalSearchPersonId: payload
      }
    case InternalSearchActionTypes.UpdatePerson:
      const personIndex = state.internalPersons.findIndex(
        (internalPerson) => internalPerson.id === payload.id
      )

      const newPerson = updatePersonObject(
        state.internalPersons[personIndex],
        payload
      )
      state.internalPersons[personIndex] = newPerson
      return { ...state }
    default:
      return state
  }
}

export const initialInternalSearchContextValues: InternalSearchContextValues = {
  ...initialInternalSearchState,
  updateFilters: noop,
  setIsLoadingSearchResults: noop,
  setSections: noop,
  setInternalPersons: noop,
  addArrayBasedInternalFilter: noop,
  removeArrayBasedInternalFilter: noop,
  clearArrayBasedFilter: noop,
  clearFilters: noop,
  setInternalSearchPage: noop,
  resetFilters: noop,
  setIsResetFilters: noop,
  setIsLookup: noop,
  setLookupSearchString: noop,
  setSelectedInternalSearchPersonId: noop,
  updatePerson: noop
}
